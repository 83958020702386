@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-backgoround: #141414;
  --main-text: #D1D3D6;
  --main-text-accented: #F8F8F8;
  --second-background: #282828;
  --second-background-header: #222222E6;
  --main-accented: #424242;
  --element-accented: #383838;
  --functional-text: 100, 131, 255;
  --transparent-btn: #07070748;
  --second-accented-navbar: #3f3f3f79;
  --second-text: #868686;
  --hover-element: #6a6a6a;
  --hover-element-accented: #7f7e7e;
  --tertiary-background: #1E1E1E;
}
:root.light-theme {
  --main-backgoround: #e7e7e7;
  --main-text: #2e2e2e;
  --main-text-accented: #ffffff;
  --second-background: #dddddd;
  --second-background-header: #dfdfdfe6;
  --main-accented: #f2f2f2;
  --element-accented: #f0f0f0;
  --functional-text: 100, 131, 255;
  --second-accented-navbar: #cdcdcdf2;
  --second-text: #868686;
}