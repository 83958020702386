.input-wrapper {
  width: 400px;
}

.input-box {
    
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input-box input {
  width: 398px;
  height: 60px;
  border: 1px solid var(--second-text);
  background-color: var(--main-backgoround);
  border-radius: 10px;
  outline: none;
  color: var(--main-text-accented);
  font-size: 12px;
  transition: 0.5s;
  padding-left: 20px;
  padding-right: 20px;
}

.input-box span {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20.48px;
  font-size: 12px;
  color: var(--second-text);
  transition: 0.5s;
  pointer-events: none;
  border-left: -100px solid rgb(var(--functional-text));
  border-right: -100px solid rgb(var(--functional-text));
}

.input-box input:focus ~ span {
  color: rgb(var(--functional-text));
  transform: translateX(20px) translateY(-7px);
  font-size: 12px;
  padding: 0 12px;
  background-color: var(--main-backgoround);
  border-left: 1px solid rgb(var(--functional-text));
  border-right: 1px solid rgb(var(--functional-text));
  letter-spacing: 0.2em;
  height: 15px;
}

.input-box input:not(:focus, :placeholder-shown) ~ span {
  color: var(--second-text);
  transform: translateX(20px) translateY(-7px);
  font-size: 12px;
  padding: 0 12px;
  background-color: var(--main-backgoround);
  border-left: 1px solid var(--second-text);
  border-right: 1px solid var(--second-text);
  letter-spacing: 0.2em;
  height: 15px;
}


.input-box input:focus {
  border: 1px solid rgb(var(--functional-text));
}

.input-box input:not(:focus, :placeholder-shown) {
  border: 1px solid var(--second-text);
}
